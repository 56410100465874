import { type Session } from '@auth/core/types';

export const getMembershipApiHeaders = (session?: Session | null) => {
  return {
    ...(session !== null && { Authorization: `Bearer ${session?.accessToken}` }),
    'Ocp-Apim-Subscription-Key': process.env.NEXT_MEMBERSHIP_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
  };
};

export const getAccountApiHeaders = (session?: Session | null) => {
  return {
    ...(session !== null && { Authorization: `Bearer ${session?.accessToken}` }),
    'Ocp-Apim-Subscription-Key': process.env.OCP_ACCOUNT_API_SUBSCRIPTION_KEY ?? '',
  };
};

export const getApiHeaders = (session?: Session | null) => {
  return {
    ...(session !== null && { Authorization: `Bearer ${session?.accessToken}` }),
    'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
  };
};
