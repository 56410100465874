export enum OrgSharedDataTag {
  GET_APPLICATION_DEFINITION = 'get-application-definition',
  GET_ASSESSMENT_QUESTIONS = 'get-assessment-questions',
  GET_BOOKMARKS = 'get-bookmarks',
  GET_CENTER_APPLICATION_DEFINITION = 'get-center-application-definition',
  GET_COMMUNICATION_PREFERENCES = 'get-communication-preferences',
  GET_COMPANIES = 'get-companies',
  GET_COUNTRIES = 'get-countries',
  GET_COUNTRY_STATES = 'get-country-states',
  GET_CUSTOMER_OPTIMIZELY_ROLES = 'get-customer-optimizely-roles',
  GET_CUSTOMER_PROFILE = 'get-customer-profile',
  GET_DESIGNATIONS = 'get-designations',
  GET_DONATION_LEVELS = 'get-donation-levels',
  GET_GENDERS = 'get-genders',
  GET_LANGUAGES = 'get-languages',
  GET_MEMBERSHIP_APPLICATION = 'get-membership-application',
  GET_MEMBERSHIP_REQUIREMENTS = 'get-membership-requirements',
  GET_OPTIMIZELY_CONTENT = 'get-optimizely-content',
  GET_OPTIMIZELY_REDIRECTS = 'get-optimizely-redirects',
  GET_PHONE_TYPES = 'get-phone-types',
  GET_PREFIXES = 'get-prefixes',
  GET_PROFILE_PICTURE = 'get-profile-picture',
  GET_RECEIPT = 'get-receipt',
  GET_RECOMMENDED_ARTICLES_FOR_YOU = 'get-recommended-articles-for-you',
  GET_SUFFIXES = 'get-suffixes',
}
