import Skeleton from 'react-loading-skeleton';

import { useCssVariable } from '@shared/utils';

import styles from './loader-strip.module.scss';

export const LoaderStrip = () => {
  const [baseColor] = useCssVariable('--mdrt-color-backgrounds-bg-primary-fill-01');
  const [highlightColor] = useCssVariable('--mdrt-color-backgrounds-bg-surface');

  return (
    <Skeleton
      baseColor={baseColor.propertyValue}
      borderRadius={0}
      className={styles['loader-strip']}
      containerClassName={styles['loader-strip-container']}
      highlightColor={highlightColor.propertyValue}
      inline={false}
    />
  );
};
